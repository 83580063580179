#certicates {
  height: calc(100% - 20px);
}

.f-40 {
  font-size: 40px;
}

.red {
  color: #F24D58;
}

.button-red {
  background-color: #F24D58;
  color: #FFF !important;
  border-color: #F24D58;
  font-weight: 500;
}

.button-red:hover {
  background-color: #FF6B75;
  border-color: #FF6B75 !important;
}

.tag {
  display: inline-block;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  padding: 0 15px;
  text-align: center;
}

.tag-red {
  background-color: #FDE4E6;
  color: #F24D58;
}

.icon-certificates {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 5px;
  padding-right: 19px;
}

.icon-certificates .icon {
  border-radius: 20px;
  background-color: #FDE5E6;
  padding: 17.94px 19.94px;
  width: 100%;
}

.icon-download {
  color: #F24E59;
}