#new-events {
  height: calc(100% - 20px);
}

.f-40 {
  font-size: 40px;
}

.yellow {
  color: #FFA900;
}

.button-yellow {
  background-color: #FFA900;
  color: #FFF !important;
  border-color: #FFA900;
  font-weight: 500;
}

.button-yellow:hover {
  background-color: #FFC147;
  border-color: #FFC147 !important;
}

.tag {
  display: inline-block;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  padding: 0 15px;
  text-align: center;
  margin-left: 10px;
}

.tag-yellow {
  background-color: #FFF2D9;
  color: #FFA900;
}

.icon-events-calendar>g>path {
  fill: #FFF2D9;
}

.icon-events-calendar>g>g path{
  fill: #FFA900;
}

.icon-edit {
  cursor: pointer;
}

.dash_text.date {
  font-size: 14px;
}

.red_info {
  color: #F24D58 !important;
  font-size: 16px;
}

.tag_payment {
  margin-left: 8px;
  cursor: pointer;
}

.hover_popover {
  max-width: 250px !important;
  font-size: 12px;
}

.eye_icon {
  font-size: 20px;
}