.quotes_regularization_footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.regularization_button {
    width: 175px;
    height: 40px;
}

.regularization_button.back {
    background-color: #FFF;
    border: 1px solid #73a91b;
    color: #73a91b;
}

#quotes-regularization .ant-form-item-explain-error {
    text-align: left !important;
    margin-bottom: 0 !important;
}

#quotes-regularization a {
    color: #73a91b;
    text-decoration: underline;
}