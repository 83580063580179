.spin_loading {
    min-height: calc(101vh - 248px);
    display: flex;
    justify-content: center;
}

.spin_loading .ant-spin {
    margin-top: 5%;
}

.edit_profile_content {
    padding: 40px;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.edit_profile_content .edit_profile_title {
    font-size: 22px;
    color: #4A4C4D;
    font-weight: 600;
    margin-bottom: 0 !important;
}

.edit_profile_content .edit_profile_divider {
    margin-top: 5px !important;
}

.edit_profile_content .edit_profile_pic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.edit_profile_content .edit_profile_pic .pic {
    width: 175px;
    height: 175px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 90px;
    border: 2px solid #73A91B;
}

.edit_profile_content .edit_profile_pic .icon_edit {
    position: absolute;
    top: 0;
    right: 20px;
    border-radius: 20px;
    padding: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit_profile_content .upload_content {
    position: relative;
}


.edit_profile_content .ant-upload-list {
    display: none !important;
}

.edit_profile_content .edit_profile_pic .ant-upload.ant-upload-select {
    position: relative;
}

.edit_profile_content .edit_profile_pic_title {
    color: #4A4C4D;
    font-size: 18px;
    font-weight: 600;
}

.edit_profile_content .access_row {
    background-color: #F3F6F9;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.edit_profile_content .buttons_edit_profile {
    margin-top: 40px;
}

.edit_profile_content .button_voltar {
    height: 40px;
    background-color: #FFF;
    width: 40%;
    max-width: 175px;
    color: #73A91B;
    border-color: #73A91B;
}

.edit_profile_content .access_row .button {
    height: 40px;
    width: 100%;
    margin-bottom: 24px;
}

.debit_cancelled {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    border: 1px dashed #ec2224;
    background-color: rgba(255, 255, 255, 0.6);
    margin-top: 20px;
    font-size: 16px;
    color: #ec2224;
    opacity: 0.4;
    border-radius: 6px;
}