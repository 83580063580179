#admin-create-event .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
}

#admin-create-event .dragger.hasBgImage .ant-upload-btn {
  background-color: #FFFFFFCC !important;
}

#admin-create-event .dragger.hasBgImage .ant-upload-drag {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#admin-create-event .dragger .ant-upload-drag {
  background-color: #EAF2DC;
  border: 1px dashed #73A91B;
  border-radius: 5px;
  padding: 30px;
  height: 480px;
}

#admin-create-event .dragger .ant-upload-drag p {
  margin: 0;
}

#admin-create-event .dragger .ant-upload-drag .ant-upload-btn {
  background-color: #FFFFFF;
  border: 1px dashed #73A91B;
  border-radius: 5px;
}

#admin-create-event .form {
  background-color: #F3F6F9;
  border-radius: 5px;
  margin-left: 20px;
  padding: 30px;
}

#admin-create-event .icon-delete {
  color: #EF9898;
  transition: .3s ease-out;
}

#admin-create-event .icon-delete:hover {
  color: #EF3838;
}

#admin-create-event .bar {
  border-color: #73A91B;
  margin: 20px 0;
}

#admin-create-event .btn-send-pdf {
  background-color: transparent;
  border: 1px solid #73A91B;
  border-radius: 5px;
  color: #73A91B;
  font-weight: bold;
}

#admin-create-event .btn-send-pdf svg {
  font-size: 16px;
}

#admin-create-event .submit {
  font-weight: 600;
  height: 46px;
  padding: 0 68px;
}

.pointer {
  cursor: pointer;
}

.icon_delete_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_list_options .ant-form-item,
.form_list_prices .ant-form-item {
  margin-bottom: 0 !important;
}

.form_list_options .ant-form-item-label, .form_list_prices .ant-form-item-label {
  display: flex;
  align-items: center;
}

.upload_col .ant-upload-wrapper {
  display: flex;
}

.upload_col .ant-upload-wrapper .ant-upload-list {
  width: 100%;
  margin-left: 20px;
  background-color: #FFF;
  border-radius: 6px;
  max-height: 40px;
  padding: 0 20px;
  border: 1px solid #d9d9d9;
}

.upload_col .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_col .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item {
  margin-top: 0 !important;
}