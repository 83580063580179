.event_subscribers {
  align-items: center;
  justify-content: center;
  display: flex;
  width: auto !important;
}

.table_image_subscribers {
  max-width: 80px;
  border-radius: 4px;
}

.event_subscribers .user_name {
  margin: 0 !important;
  font-size: 16px;
}

.event_subscribers .title {
  font-size: 20px;
  color: #4A4C4D;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0 !important;
}

.event_subscribers .ant-table {
  margin-top: 40px !important;
}

.events_payment {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  line-height: 1;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}

.events_payment.payed {
  font-weight: 500;
  background-color: #ffff;
  border: 1px solid #73A91B;
  color: #73A91B;
  max-width: 90px;
}

.events_payment.waiting_payment {
  background-color: #ffff;
  color: #b1b1b1;
  border: 1px solid #b1b1b1;
  font-weight: 500;
  max-width: 160px;
}

.events_payment.canceled {
  background-color: #ffff;
  color: red;
  border: 1px solid red;
  font-weight: 500;
}