#settings-parecer-form {
  width: 100%;
}

.settings_content {
  padding: 40px;
  border-radius: 5px;
  background-color: #ffffff;
}

.setting_divider {
  margin-top: 5px !important;
}

.settings_content .settings_title {
  font-size: 22px;
  color: #4a4c4d;
  font-weight: 600;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.units_radio .ant-radio-button-wrapper {
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.not_disabled .ant-select-selector {
  background-color: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.not_disabled .ant-select-selection-item {
  color: #000 !important;
}

.not_disabled .ant-select-arrow {
  display: none !important;
}
