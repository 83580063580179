.import_users_info {
    margin-top: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 6px;
}

.import_users_info .title {
    text-align: center;
}

.import_users_info .number {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
}

#form-import-users {
    margin-top: 40px;
}

.import_users_dragger .ant-upload-list-item {
    height: 50px !important;
    padding: 0 20px;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    margin-top: 20px !important;
}

.import_users_dragger .ant-upload-list-item-name {
    font-size: 20px;
}

.import_users_dragger .anticon-paper-clip {
    font-size: 20px !important;
}

.import_users_dragger .anticon.anticon-delete {
    font-size: 20px;
}

.import_users_dragger .ant-upload-list-item-action {
    opacity: 1 !important;
}

.import_users_table td, .import_users_table th {
    min-width: 200px !important;
}