.event_details_content {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
}

#form-add-to-cart-event .ant-input-number {
  width: 100%;
}

.prices_options {
  padding: 40px;
  background-color: #f3f6f9;
  border-radius: 5px;
  position: relative;
}

.receipt_example {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 40px !important;
  border: 1px solid #73a91b;
  background-color: #fff;
  -webkit-box-shadow: -3px -3px 10px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -3px -3px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: -3px -3px 10px -5px rgba(0, 0, 0, 0.2);
}

.event_img {
  width: 100%;
  border-radius: 5px;
}

.event_date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.event_date span {
  margin-right: 5px;
}

.event_description {
  font-size: 16px;
}

.event_file_name {
  font-size: 16px;
  cursor: pointer;
}

.enrolled_warning {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: "Poppins";
  top: 0;
  left: 0;
}

.notpayed_enrolled_warning {
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 4px;
  font-family: "Poppins";
  top: 0;
  left: 0;
  min-height: 200px;
}

.icon_enrolled {
  margin-left: 10px;
  color: #73a91b;
}

.row_options {
  padding: 20px;
  background: #eaedf0;
  border-radius: 6px;
  width: 100% !important;
}

.row_options.hidden {
  display: none !important;
}

.row_options .ant-form-item {
  margin-bottom: 0 !important;
}

.row_promotion {
  padding: 20px;
  background: #e0e8d5;
  border-radius: 6px;
}

.row_promotion .ant-form-item {
  margin-bottom: 0 !important;
}

.promotion_value {
  font-size: 24px;
  font-weight: 500;
}

.promotion_title {
  font-size: 16px;
}

.not_input {
  background-color: #eaedf0 !important;
}

.not_input input {
  background-color: #eaedf0 !important;
  pointer-events: none !important;
  border-color: #eaedf0 !important;
  pointer-events: none !important;
}

#form-add-to-cart-event .ant-form-item-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_price {
  max-width: 100px;
}

.not_input_price {
  background-color: #f3f6f9 !important;
}

.not_input_price input {
  background-color: #f3f6f9 !important;
  pointer-events: none !important;
  border-color: #f3f6f9 !important;
  pointer-events: none !important;
}

.not_input_price textarea {
  background-color: #f3f6f9 !important;
  pointer-events: none !important;
  border-color: #f3f6f9 !important;
  pointer-events: none !important;
}

.not_input textarea {
  background-color: #eaedf0 !important;
  pointer-events: none !important;
  border-color: #eaedf0 !important;
  pointer-events: none !important;
}

.row_price_profession .ant-form-item {
  margin-bottom: 0 !important;
}
