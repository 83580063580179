#content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f6f9;
  transition: opacity 1s ease;
  min-height: calc(100vh - 60px);
}

#content.login {
  background: transparent linear-gradient(270deg, #e0ead8 20%, #ffffff 50%) 0% 0% no-repeat padding-box;
}

#content.resetPasswordEmail {
  background: transparent linear-gradient(270deg, #e0ead8 20%, #ffffff 50%) 0% 0% no-repeat padding-box;
}

#content.register {
  background: transparent linear-gradient(270deg, #e0ead8 20%, #ffffff 50%) 0% 0% no-repeat padding-box;
}

#app_content {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 20px 60px 20px;
}

.popover-send-test {
  width: 400px !important;
}

#form-email-test .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

#form-email-test .ant-form-item-control-input-content .ant-form-item {
  margin: 0 !important;
  width: 100%;
}

#form-email-test .ant-form-item-row {
  width: 100% !important;
}

/* ---------------------------------------------------------
------------------------------------------------------------
APP CSS 
------------------------------------------------------------
------------------------------------------------------------ */

.ant-modal-open {
  overflow: inherit !important;
}

#loading-routes {
  width: 100%;
  min-height: 100vh;
}

#loading-routes .ant-spin-container {
  min-height: 100vh;
}

#loading-routes .ant-spin-container > div {
  min-height: 100vh;
}

#loading-div {
  height: 100vh;
  width: 100%;
  position: absolute;
  background-color: #000;
  z-index: 99999;
  transition: opacity 0.55s ease;
}

#loading-div > div {
  top: 30%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#loading-div img {
  margin-bottom: 30px;
}

/* ---------------------------------------------------------
------------------------------------------------------------
GERAL 
------------------------------------------------------------
------------------------------------------------------------ */

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  transform: translate(-50%, -50%) scale(1);
}
.col-end {
  display: flex;
  justify-content: flex-end;
}

.ant-form-item-explain-error {
  margin-bottom: 20px !important;
  margin-top: 5px !important;
}

.ant-input-number input {
  font-family: "Poppins";
}

.payment_warning .ant-modal-content {
  padding: 40px 20px !important;
  border-radius: 5px !important;
}

.payment_button_warning {
  color: #fff !important;
  font-weight: 500;
  background-color: #f64e60;
  border-color: #f64e60;
  box-shadow: none;
}

.payment_button_warning:hover {
  color: #fff !important;
  font-weight: 500;
  background-color: rgba(246, 78, 96, 0.6) !important;
  border-color: rgba(246, 78, 96, 0.6) !important;
}

.payment_warning .ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_warning .info_icon {
  max-width: 100px;
}

.payment_warning .warning_text {
  color: #d1000e;
}

.payment_options {
  width: 100%;
  top: 40px;
}

.payment_options .ant-modal-content {
  padding: 40px !important;
}

.payment_options .payment_title {
  font-size: 22px;
  color: #4a4c4d;
  font-weight: 600;
  margin: 0 !important;
}

.payment_options .payment_text {
  font-size: 16px;
  color: #4a4c4d;
  margin: 0 !important;
  text-align: center;
}

.payment_options .ant-radio-group {
  width: 100%;
}

.payment_options .ant-radio-button-wrapper {
  height: 100%;
}

.payment_options .ant-radio-button-wrapper.method_img {
  height: 100%;
  max-height: 100px;
}

.payment_options .user_profession {
  font-size: 16px;
  color: #4a4c4d;
  font-weight: 600;
}

.payment_options .payment_type {
  font-size: 16px;
  color: #4a4c4d;
}

.payment_options .payment_separator {
  width: 50%;
  height: 2px;
  background: #73a91b;
  margin: 20px 0 20px 0;
  border: 0 !important;
}

.payment_options .payment_value {
  font-size: 55px;
  color: #73a91b;
  font-weight: 600;
  line-height: 90px;
}

.payment_options .payment_old_value {
  font-size: 20px;
  text-decoration: line-through #b1b1b1;
  color: #c1c1c1;
  font-weight: 600;
}

.payment_options .payment_select {
  background: #f3f6f9;
  border-color: #4a4c4d;
  width: 140px;
  height: 40px;
}

.payment_options .ant-radio-button.ant-radio-button-checked,
.payment_options .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #73a91b;
  border-color: #73a91b;
  background: rgba(115, 169, 27, 0.1);
}

.payment_options .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .payment_select {
  color: #fff;
  border-color: #73a91b;
  background: #73a91b;
}

.payment_options .payment_select:hover {
  color: #fff !important;
  border-color: #73a91b !important;
  background: #73a91b;
}

.payment_options .payment_text.bold {
  font-weight: 600;
}

.payment_options .payment_method {
  margin-bottom: 20px;
}

.n_member_table {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  border: 1px solid #000;
  border-radius: 5px;
  line-height: 1;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}

/* ---------------------------------------------------------
------------------------------------------------------------
ANTD 
------------------------------------------------------------
------------------------------------------------------------ */

.ant-form-item-required::before {
  color: #4a4c4d !important;
}

.ant-form-item-label label {
  color: #4a4c4d !important;
}

.ant-picker {
  width: 100%;
}

.ant-picker input {
  font-family: "Poppins";
}
