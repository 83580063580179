#admin-create-voucher .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
}

#admin-create-voucher .form {
  background-color: #F3F6F9;
  border-radius: 5px;
  padding: 30px;
}

#admin-create-voucher .bar {
  border-color: #73A91B;
  margin: 20px 0;
}

#admin-create-voucher .ant-input-number {
  width: 100%;
}

#admin-create-voucher .submit_button {
  width: 175px;
  height: 40px;
  color: #FFF !important;
  font-weight: 500;
  background-color: #73a91b;
  border-color: #73a91b;
  box-shadow: none;
}

#admin-create-voucher .submit_button:hover {
  color: #73a91b !important;
  font-weight: 500;
  background-color: #fff;
  border-color: #73a91b
}

.voucher_type_images {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.voucher_type_images .voucher_type_images_item {
  height: 70px;
  width: 50%;
  /*border-radius: 6px !important;
  border: 1px solid #d9d9d9*/
}

.voucher_type_images .voucher_type_images_item span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.voucher_type_images .voucher_type_images_item img {
  max-height: 50px;
  margin-right: 10px;
}

.voucher_type_images .voucher_type_images_item div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.voucher_type_images .voucher_type_images_item.ant-radio-button-wrapper-checked {
  background: rgba(115, 169, 27, 0.1);
  /*border: 1px solid #73a91b;*/
}

/*.voucher_type_images .voucher_type_images_item.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}*/