.payment_options .ant-steps {
  display: none !important;
}

.ant-input-group-addon {
  padding: 0 !important;
}

.payment_method .ant-radio-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_method span:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.payment_method span:nth-child(2) img {
  max-height: 40px;
  min-height: 40px;
  margin: 0 10px;
}

.payment_button {
  width: 175px;
  height: 40px;
}

.payment_button.back {
  background-color: #fff;
  border: 1px solid #73a91b;
  color: #73a91b;
}

.payment_options .ant-form-item-explain-error {
  text-align: center;
}

.payment_options #payment-form-step-two .ant-form-item-explain-error {
  text-align: left !important;
  margin-bottom: 0 !important;
}

.payment_options .payment_card_group {
  max-width: 850px;
  margin-top: 40px;
  margin: auto;
}

.payment_options .payment_card_group .payment_card {
  padding: 20px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  justify-content: flex-start;
  align-items: center;
  background: #f3f6f9;
  box-shadow: 0px 3px 10px #00000029;
}

.payment_options .payment_card_group .payment_card.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.payment_options .payment_card_group .payment_card span:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment_options .payment_card_group .payment_card p {
  margin: 0 !important;
}

.payment_options .step_reference {
  background: #f3f6f9;
  padding: 30px;
}

.payment_options .step_reference img {
  max-width: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_options .step_reference p.payment_info {
  margin: 5px 0;
}

.payment_options .step_reference p.payment_info.date_expiry {
  margin: 35px 0 0 0;
}

.payment_options .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 600;
  margin-bottom: 10px;
}

.payment_options .img_method {
  width: 100%;
  max-width: 140px;
  margin-bottom: 20px;
}

.payment_options .img_method.debit {
  width: 100%;
  max-width: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.payment_options .img_method.mbway {
  width: 100%;
  max-width: 110px !important;
  margin-bottom: 20px;
}

.payment_options .warning_text {
  font-size: 12px;
  color: #4a4c4d;
  margin-top: 20px;
  margin-bottom: 30px;
}

#payment-form-step-two .ant-form-item {
  margin: 0 !important;
}

#payment-form-step-two .ant-form-item .warning_price {
  margin-bottom: 0 !important;
}

#payment-form-step-two .ant-form-item label {
  font-weight: 500;
}

#payment-form-step-two .ant-form-item label::before {
  display: none !important;
}

#payment-form-step-one .voucher_div {
  justify-content: space-between !important;
  width: 100% !important;
  flex-wrap: nowrap;
}

.info_form_step_one {
  padding: 20px;
  border: 2px solid #f3f6f9;
  border-radius: 6px;
}

.info_form_step_one .title {
  font-size: 16px;
}

.info_form_step_one .method {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.info_form_step_one .method .info_img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.info_form_step_one .method img.debit {
  max-width: 40px;
  width: 100%;
}

.info_form_step_one .method img.mbway {
  max-width: 80px;
  width: 100%;
}

.info_form_step_one .method img.mb {
  max-width: 120px;
  width: 100%;
}

.info_form_step_one .type {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.info_form_step_one .type .info {
  font-size: 24px;
  font-weight: 600;
}

.info_form_step_one .value {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.info_form_step_one .value .info {
  font-size: 24px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  #payment-form-step-one .voucher_div {
    flex-wrap: wrap !important;
  }

  #payment-form-step-one .voucher_div .button_div {
    margin-left: 0 !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    margin-top: 20px;
    height: auto !important;
  }
}

.preview_payment_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f3f6f9;
  box-shadow: 0px 3px 10px #00000029;
  height: 100%;
}

.preview_payment_card.debit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f3f6f9;
  box-shadow: 0px 3px 10px #00000029;
}

.preview_payment_card.debit.disabled {
  opacity: 0.5;
}

.preview_payment_card.debit .payment_value {
  font-size: 30px !important;
  line-height: 40px !important;
}
