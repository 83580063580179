#admin-list-users .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
}

#admin-list-users ul.ant-pagination.ant-table-pagination {
  padding-top: 20px;
}

#admin-list-users ul.ant-pagination.ant-table-pagination li.ant-pagination-options {
  left: 0;
  position: absolute;
}

#admin-list-users li.ant-pagination-item.ant-pagination-item-active {
  background-color: #73A91B;
  border-color: #73A91B;
}

#admin-list-users .quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  line-height: 1;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}

#admin-list-users .quotes.payed {
  font-weight: 500;
  background-color: #ffff;
  border: 1px solid #73A91B;
  color: #73A91B;
  max-width: 90px;
}

#admin-list-users .quotes.not_payed {
  background-color: #ffff;
  color: #D1000E;
  border: 1px solid #D1000E;
  font-weight: 500;
  max-width: 140px;
}

#admin-list-users .quotes.waiting_payment {
  background-color: #ffff;
  color: #b1b1b1;
  border: 1px solid #b1b1b1;
  font-weight: 500;
  max-width: 140px;
}

#admin-list-users .n_member_table {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  border: 1px solid #000;
  border-radius: 5px;
  line-height: 1;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}