#content.login {
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-content {
  width: 100%;
  height: calc(100% - 60px);
}