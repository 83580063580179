#admin-list-events .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
}

.table .table__image {
  border-radius: 5px;
  height: 46px;
  width: 46px;
}

.ant-table-wrapper .ant-table-thead >tr>th,
.ant-table-wrapper .ant-table-thead >tr>td {
  background: transparent;
  border: none;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}

#admin-list-events ul.ant-pagination.ant-table-pagination {
  padding-top: 20px;
}

#admin-list-events ul.ant-pagination.ant-table-pagination li.ant-pagination-options {
  left: 0;
  position: absolute;
}

#admin-list-events li.ant-pagination-item.ant-pagination-item-active {
  background-color: #73A91B;
  border-color: #73A91B;
}