.register-steps {
  max-width: 600px !important;
  margin: auto;
}

.register-steps .ant-steps-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
  padding-inline-start: 12px !important;
}

.register-steps .ant-steps-item::after {
  display: none !important;
}

.register-steps .ant-steps-item:nth-child(2) {
  margin-right: 10px;
  margin-left: 10px;
}

.register-steps .ant-steps-item-container {
  padding-top: 12px;
}

.register-steps .ant-steps-item::before {
  width: 100% !important;
  inset-inline-start: 0 !important;
  height: 8px !important;
  border: 1px solid #BDBCBC !important;
  background-color: #BDBCBC !important;
}

.register-steps .ant-steps-item.ant-steps-item-active::before,
.register-steps .ant-steps-item.ant-steps-item-finish::before {
  border: 1px solid #73A91B !important;
  background-color: #73A91B !important;
}

.register-steps .ant-steps-item .ant-steps-item-icon {
  display: none;
}

.register-steps .ant-steps-item.ant-steps-item-active .ant-steps-item-icon>.ant-steps-icon {
  color: #73A91B;
}

.register-steps .ant-steps-item.ant-steps-item-active .ant-steps-item-title,
.register-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-title {
  color: #73A91B !important;
}