.quotes_drawer .user_name {
    margin: 0 !important;
    font-size: 16px;
}

.quotes_drawer .title {
    font-size: 20px;
    color: #4A4C4D;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0 !important;
}

.status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: #fff;
    border-radius: 4px;
    max-width: 150px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    font-size: 12px
}

.status.pago {
    background-color: #73a91b;
    border: 1px solid #73a91b;
}

.status.cancelado {
    background-color: transparent;
    border: 1px solid #ec0224;
    color: #ec0224;
}

.status.processing {
    color: #73a91b;
    border: 1px solid #73a91b;
}

.status.próximo.pagamento {
    color: #73a91b;
    border: 1px solid #73a91b;
}

.img_events_list {
    max-width: 80px;
    border-radius: 5px;
}

.events_list_name {
    color: rgba(0, 0, 0, 0.88);
}

.expanded_table {
    margin: 0px !important;
    padding: 20px;
}

.expanded_table .ant-table {
    margin-block: 0px !important;
    margin-inline: 0px !important;
    background: #F3F6F9;
    border-radius: 6px;
}

.ant-table-row-expand-icon:hover, .ant-table-row-expand-icon:active, .ant-table-row-expand-icon:focus {
    color: #73A91B !important;
}