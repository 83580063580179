.edit_profile_content.drawer {
    padding: 20px !important;
}

.edit_profile_content .edit_profile_pic.admin {
    height: 100% !important;
}

.user-payment-modal .ant-modal-content {
    padding: 40px;
}

.user-payment-modal .ant-modal-footer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel-payment {
    background-color: #000;
}