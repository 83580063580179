.ant-layout-footer {
  width: 100% !important;
  padding: 0 !important;
}

.ant-layout-header {
  background-color: transparent !important;
  height: auto !important;
  min-height: 90px !important;
  padding-inline: 0 !important;
}

.footer {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1000e;
  color: #fff;
}

.footer p {
  font-size: 12px;
}

.footer a {
  color: #fff;
}

.main_layout {
  background-color: #f3f6f9 !important;
}

.main_layout main {
  min-height: calc(100vh - 90px - 60px) !important;
}

/* -------------------------------- */
/* -------------------------------- */
/* -------------------------------- */
/* ---------- HEADER BAR ---------- */
/* -------------------------------- */
/* -------------------------------- */
/* -------------------------------- */

.header_bar {
  padding: 20px;
  border-radius: 5px;
}

.logo_header {
  max-width: 150px;
  cursor: pointer;
}

.header_bar .icons img {
  max-height: 40px;
}

.icon_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
  border: 2px solid #73a91b;
  border-radius: 5px;
  transition: background-color 0.1s ease-out 50ms;
}

.icon_logout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
  border: 2px solid #f24e59;
  border-radius: 5px;
  transition: background-color 0.1s ease-out 50ms;
}

.icon_settings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
  border: 2px solid #73a91b;
  border-radius: 5px;
  transition: background-color 0.1s ease-out 50ms;
}

.icon_settings img,
.icon_profile img,
.icon_logout img {
  width: 100%;
  height: 100%;
}

.icon_settings:hover {
  background-color: #eaf2dd;
}

.icon_website {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
  border: 2px solid #73a91b;
  border-radius: 5px;
  transition: background-color 0.1s ease-out 50ms;
}

.icon_website .anticon {
  color: #73a91b;
  font-size: 20px;
}

.icon_website:hover {
  background-color: #eaf2dd;
}

.icon_profile:hover {
  background-color: #eaf2dd;
}

.icon_logout:hover {
  background-color: #f8d9db;
}

.ant-badge .ant-badge-count {
  inset-inline-end: 15px !important;
}

/* -------------------------------- */
/* -------------------------------- */
/* -------------------------------- */
/* ---------- USER BAR ---------- */
/* -------------------------------- */
/* -------------------------------- */
/* -------------------------------- */

.user_bar {
  padding: 40px;
  border-radius: 5px;
  background-color: #ffffff;
}

.user_bar .img-perfil {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  min-height: 160px !important;
  max-height: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important;
}

.user_bar .welcome_text p {
  color: #bdbcbc;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.user_bar .user_name p {
  font-size: 40px;
  font-weight: 600;
  color: #4a4c4d;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1;
}

.user_bar .user_profession p {
  color: #4a4c4d;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
}

.user_bar .edit_profile {
  margin-top: 20px;
}

.user_bar .edit_profile p {
  color: #bdbcbc;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  margin-right: 10px;
}

.user_bar .edit_profile .icon {
  height: 30px;
  width: 30px;
  background-image: url("../../assets/icons/Edit-Off.svg");
  background-size: contain;
  transition: background-image 0.1s ease-out 50ms;
  cursor: pointer;
}

.user_bar .edit_profile .icon:hover {
  background-image: url("../../assets/icons/Edit-On.svg");
}

.user_bar .number {
  font-size: 30px;
  color: #73a91b;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background-color: #e0ead8;
  border-radius: 5px;
}

.user_bar .number p {
  margin: 0 !important;
  line-height: 1;
}

@media only screen and (max-width: 600px) {
  .ant-layout-header .ant-col {
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
  }
}
