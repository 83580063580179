.payment-needed {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.payment-needed-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px !important;
  border-radius: 5px !important;
  background: #fff;
  box-shadow: 0px 3px 10px #00000029;
}

.payment-needed-content .warning_text {
  color: #D1000E;
}

.payment-needed-content .payment_button_warning {
  color: #FFF !important;
  font-weight: 500;
  background-color: #F64E60;
  border-color: #F64E60;
  box-shadow: none;
}

.payment-needed-content .payment_button_warning:hover {
  color: #FFF !important;
  font-weight: 500;
  background-color: rgba(246, 78, 96, 0.6);
  border-color: rgba(246, 78, 96, 0.6);
}