.quotes_modal .ant-modal-content {
  padding: 40px !important;
}

.quotes_modal .user_name {
  margin: 0 !important;
  font-size: 16px;
}

.quotes_modal {
  width: 1300px !important;
}

.quotes_modal .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0 !important;
}

.quotes_modal table {
  margin-top: 40px;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  max-width: 100px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  font-size: 12px;
}

.status.pago {
  background-color: #73a91b;
  border: 1px solid #73a91b;
}

.status.cancelado {
  background-color: transparent;
  border: 1px solid #ec0224;
  color: #ec0224;
}

.status.processing {
  color: #73a91b;
  border: 1px solid #73a91b;
}

.status.próximo.pagamento {
  color: #73a91b;
  border: 1px solid #73a91b;
}
