#list-events-guest .grid {
  display: grid;
  gap: 53px;
}

#list-events-guest .item {
  background-color: #F3F6F9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding: 20px;
}

#list-events-guest .item_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  text-align: center;
}

#list-events-guest .item_title {
  color: #050505;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 0 !important;
}

#list-events-guest .item_image {
  height: 260px;
  width: 100%;
}

#list-events-guest .item_image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}

#list-events-guest .item_cta {
  height: 46px;
  margin: auto auto 0 auto;
  padding: 0 54px;
}

#list-events-guest .pagination {
  padding-top: 40px;
}

li.ant-pagination-item,
li.ant-pagination-next,
li.ant-pagination-prev {
  background-color: #FFFFFF;
  border: 1px solid #D9E1E7CC !important;
}

li.ant-pagination-item.ant-pagination-item-active {
  background-color: #BDBCBC;
  border-color: #BDBCBC;
}

li.ant-pagination-item.ant-pagination-item-active * {
  color: #FFFFFF !important;
}

.item_date {
  color: #BDBCBC;
}

.item_description {
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty_search {
  height: 400px;
  font-size: 24px;
  padding-top: 20px;
  color: #BDBCBC;
  display: flex;
  justify-content: center;
  align-items: center;
}


.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 20px;
}

.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

.swiper-pagination-bullet-active {
  color: #fff !important;
  background: #007aff !important;
}