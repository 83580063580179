.auth-layout {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-layout .ant-layout-footer {
  padding: 0 !important;
}

.auth-layout {
  background: linear-gradient(90deg, rgba(115,169,27,0) 70%, #E0EAD8 100%) !important;
}