.dash_card_admin {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px !important;
  margin-bottom: 20px;
}

.dash_card {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px !important;
  margin-bottom: 20px;
}

.dash_title {
  font-size: 20px;
  font-weight: 600;
  color: #4a4c4d;
  margin-top: 0 !important;
}

.dash_text {
  font-size: 16px;
  color: #4a4c4d;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.dash_text.bold {
  font-weight: 600;
}

.dash_text.grey {
  color: #bdbcbc;
}

.green_button {
  background-color: #73a91b;
  color: #fff !important;
  border-color: #73a91b;
  font-weight: 500;
}

.green_button:hover {
  background-color: #8dce23;
  border-color: #8dce23 !important;
}

.gray_button {
  background-color: #bdbcbc;
  color: #fff !important;
  border-color: #bdbcbc;
  font-weight: 500;
}

.gray_button:hover {
  background-color: #fff;
  border-color: #bdbcbc !important;
  color: #bdbcbc !important;
}

.transparent_gray_button {
  background-color: #fff;
  color: #bdbcbc !important;
  border-color: #bdbcbc;
  font-weight: 500;
}

.transparent_gray_button:hover {
  background-color: #bdbcbc;
  border-color: #bdbcbc !important;
  color: #fff !important;
}

.transparent_button {
  border-color: #73a91b;
  color: #73a91b;
  box-shadow: none;
  font-weight: 500;
}

.transparent_button:hover {
  border-color: #73a91b !important;
  background-color: rgba(115, 169, 27, 0.2);
  border-color: rgba(115, 169, 27, 0.2);
  color: #73a91b !important;
}

.transparent_button_yellow {
  border-color: #ffa900;
  color: #ffa900;
  box-shadow: none;
  font-weight: 500;
}

.transparent_button_yellow:hover {
  border-color: #ffa900 !important;
  opacity: 0.8;
  color: #ffa900 !important;
}

.transparent_button_red {
  border-color: #ec2224;
  color: #ec2224;
  box-shadow: none;
  font-weight: 500;
}

.transparent_button_red:hover {
  border-color: #ec2224 !important;
  opacity: 0.8;
  color: #ec2224 !important;
}

.content {
  position: relative;
}

.dash_admin_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.dash_admin_item img {
  width: 100%;
  max-width: 90px;
}

.dash_statistics_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.dash_statistics_item img {
  max-width: 60px;
}

.dash_table {
  background-color: #fff;
  border-radius: 5px;
}

.img_name_table {
  display: flex;
  align-items: center;
}

.img_name_table .img {
  max-width: 65px;
  margin-right: 10px;
}

.img_name_table .img img {
  width: 100%;
}

.dash_table .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
  margin: 0 !important;
}

.dash_card_admin div {
  border-right: 1px solid #f3f6f9;
}

.dash_card_admin div:last-child {
  border-right: 0;
}

/*#tabs_admin_dash .ant-tabs-content {
    min-height: calc(100vw - 1200px);
}*/

.dash_statistics_item {
  flex: 0 0 19% !important;
  max-width: 19% !important;
}

.user_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_img .img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  background-size: cover;
  background-position: center;
  max-width: 65px;
  max-height: 65px;
  border-radius: 10px;
}

.img_default {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_default img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  width: 100%;
  height: 100%;
  display: flex;
}
