#admin-create-event .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
}

#admin-create-event .dragger.hasBgImage .ant-upload-btn {
  background-color: #ffffffcc !important;
}

#admin-create-event .dragger.hasBgImage .ant-upload-drag {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#admin-create-event .dragger .ant-upload-drag {
  background-color: #eaf2dc;
  border: 1px dashed #73a91b;
  border-radius: 5px;
  padding: 30px;
  height: 480px;
}

#admin-create-event .dragger .ant-upload-drag p {
  margin: 0;
}

#admin-create-event .dragger .ant-upload-drag .ant-upload-btn {
  background-color: #ffffff;
  border: 1px dashed #73a91b;
  border-radius: 5px;
}

#admin-create-event .form {
  background-color: #f3f6f9;
  border-radius: 5px;
  margin-left: 20px;
  padding: 30px;
}

#admin-create-event .icon-delete.disabled {
  pointer-events: none;
  opacity: 0.4;
  color: #ef9898;
  transition: 0.3s ease-out;
}

#admin-create-event .icon-delete {
  color: #ef9898;
  transition: 0.3s ease-out;
  font-size: 16px;
}

#admin-create-event .icon-delete:hover {
  color: #ef3838;
}

#admin-create-event .bar {
  border-color: #73a91b;
  margin: 20px 0;
}

#admin-create-event .btn-send-pdf {
  background-color: transparent;
  border: 1px solid #73a91b;
  border-radius: 5px;
  color: #73a91b;
  font-weight: bold;
}

#admin-create-event .btn-send-pdf svg {
  font-size: 16px;
}

#admin-create-event .submit {
  font-weight: 600;
  height: 46px;
  padding: 0 68px;
}

.pointer {
  cursor: pointer;
}

#description {
  background-color: #fff;
  border-radius: 8px;
}

.ql-editor {
  font-family: "Poppins";
  font-size: 16px;
  border-radius: 4px;
}

.ql-editor p {
  line-height: 1.42;
  margin: 10px 0 !important;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-color: #d9d9d9 !important;
}

.ql-container.ql-snow {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: #d9d9d9 !important;
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
}

.communication-quill .ql-container.ql-snow {
  min-height: 400px !important;
  max-height: 400px !important;
}

.quill > .ql-container > .ql-editor::before {
  font-size: 16px;
  font-weight: 300 !important;
  font-style: normal !important;
  color: #c6c6c6;
  top: 22px;
}
