.spin_loading {
    min-height: calc(101vh - 248px);
    display: flex;
    justify-content: center;
}
.spin_loading .ant-spin {
    margin-top: 5%;
}

.form_department_content {
    padding: 40px;
    border-radius: 5px;
    background-color: #FFFFFF;
    margin-top: 40px;
}

.form_department_content .ant-select-disabled .ant-select-selector {
    background: #FFF !important;
    color: #000 !important
}

.form_department_content label {
    font-weight: 600;
    color: #000 !important;
}

.labor_form_item .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
}

.labor_form_item .ant-form-item-control-input-content .labor_label {
    margin-right: 20px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 14px;
    font-weight: 600;
}

.form_department_content .title {
    font-size: 20px;
    color: #4a4c4d;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px
}

#parecer-form {
    max-width: 800px;
    width: 100%;
    margin-top: 50px;
}

#parecer-form .button_voltar {
    height: 40px;
    background-color: #FFF;
    min-width: 175px;
    color: #73A91B;
    border-color: #73A91B;
}

.form_department_text {
    font-size: 16px;
}