.shopping-cart-steps {
  display: none !important;
}

.shopping-cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.shopping-cart-item .image-info {
  display: flex;
  align-items: center;
}

.shopping-cart-item .image-info .image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  min-width: 80px;
  background-color: #dfdfe0;
  border-radius: 20px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shopping-cart-item .image-info .info .name {
  color: #4a4c4d;
  font-size: 20px;
  font-weight: bold;
}

.shopping-cart-item .image-info .info .price .price-item,
.shopping-cart-item .image-info .info .option .option-item {
  color: #4a4c4d;
  font-size: 14px;
}

.shopping-cart-item .image-info .info .price .price-item .old_price {
  margin-right: 5px;
  text-decoration: line-through #000;
  color: #c1c1c1;
}

.shopping-cart-item .total-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.shopping-cart-item .total-info .total {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  min-width: 150px;
}

.shopping-cart-item .total-info .total .price_with_voucher {
  margin-left: 20px;
}

.shopping-cart-item .total-info .total .price_without_voucher.overline {
  text-decoration: line-through #000;
  color: #c1c1c1;
  font-size: 18px;
}

.shopping-cart-item .total-info .edit-remove {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopping-cart-item .total-info .edit-remove .edit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.shopping-cart-item .total-info .edit-remove .edit .edit-icon,
.shopping-cart-item .total-info .edit-remove .remove .remove-icon {
  font-size: 18px;
  cursor: pointer;
}

.shopping-cart-item .total-info .edit-remove .remove {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-all-values {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  margin-right: 90px;
}

.total-all-values-payment {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  margin-right: 40px;
}

.dados-faturacao-title {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  font-size: 18px;
  color: #4a4c4d;
  font-weight: 600;
}

.form-shopping-cart-faturacao label {
  color: #4a4c4d !important;
  font-weight: 500;
}

.form-shopping-cart-faturacao .ant-form-item-required::before {
  color: #4a4c4d !important;
}

.radio-payment-method {
  width: 100%;
}

.radio-payment-method .ant-radio-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
}

.radio-payment-method span:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.radio-payment-method span:nth-child(2) img {
  height: 30px;
}

.radio-payment-method .ant-radio-button.ant-radio-button-checked {
  background-color: #eaf2dd !important;
  border-radius: 6px;
}

.empty-shopping-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.empty-shopping-cart .title {
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 8px;
}

.empty-shopping-cart .text {
  font-size: 20px;
  margin-bottom: 8px;
}

.empty-shopping-cart .button {
  font-size: 20px;
  color: #73a91b;
  font-weight: 600;
  cursor: pointer;
}

.invoice-data {
  display: flex;
  padding: 20px;
  background: #f3f6f9;
  border-radius: 5px;
  flex-wrap: wrap;
}

.invoice-data div {
  width: 50%;
  line-height: 30px;
}

.payment_method_info {
  display: flex;
  padding: 20px;
  background: #f3f6f9;
  border-radius: 5px;
  margin-top: 20px;
}

.payment_method_info .img {
  display: flex;
  margin-right: 40px;
}

.payment_method_info .payment_info_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-title {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  font-size: 18px;
  color: #bdbcbc;
  font-weight: 600;
}

.button-close-payment {
  margin-top: 40px !important;
}

.voucher_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 30px 30px 30px;
  background-color: #f3f6f9;
  flex-wrap: nowrap;
}

.voucher_div .title {
  width: 400px;
}

.submit_voucher {
  width: 175px;
  height: 40px;
  color: #fff !important;
  font-weight: 500;
  background-color: #73a91b;
  border-color: #73a91b;
  box-shadow: none;
}

.submit_voucher:hover {
  color: #73a91b !important;
  font-weight: 500;
  background-color: #fff;
  border-color: #73a91b;
}

.form-shopping-cart-faturacao #payment_method {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-shopping-cart-faturacao .ant-radio-button-wrapper {
  width: 50%;
}
