#admin-edit-voucher .title {
  font-size: 20px;
  color: #4a4c4d;
  font-weight: 700;
  text-align: left;
}

#admin-edit-voucher .form {
  background-color: #F3F6F9;
  border-radius: 5px;
  padding: 30px;
}

#admin-edit-voucher .bar {
  border-color: #73A91B;
  margin: 20px 0;
}

#admin-edit-voucher .submit {
  background-color: #F3F6F9 ;
  border-color: #F3F6F9;
  color: #595C5D;
  font-weight: bold;
  height: 46px;
  padding: 0 68px;
}

#admin-edit-voucher .ant-input-number {
  width: 100%;
}



#admin-edit-voucher .submit_button {
  width: 175px;
  height: 40px;
  color: #FFF !important;
  font-weight: 500;
  background-color: #73a91b;
  border-color: #73a91b;
  box-shadow: none;
}

#admin-edit-voucher .submit_button:hover {
  color: #73a91b !important;
  font-weight: 500;
  background-color: #fff;
  border-color: #73a91b
}