#content.resetPassword {
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#reset-password-content {
    width: 100%;
    height: calc(100% - 60px);
}

.button-login {
    height: 40px;
    background-color: #73A91B;
    min-width: 175px;
    color: #FFF;
}

.button-login:hover {
    color: #FFF !important;
    background-color: #90D225 !important;
    border-color: #90D225 !important;
}

.button-login:hover span {
    border-color: #90D225 !important;
}

.password-text {
    color: #4A4C4D !important;
}

.password-text a {
    text-decoration: underline;
    color: #4A4C4D !important;
}

.register-text {
    color: #4A4C4D !important;
    margin-top: 50px
}

.register-text a {
    text-decoration: underline;
    color: #4A4C4D !important;
}